// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// `.env.ts` is generated by the `npm run env` command
// import env from './.env';

const production = 'true';
const serverUrl = 'https://ruckit-platform.herokuapp.com/api/';
const imageServerUrl = 'http://ruckit-platform.s3-website-us-east-1.amazonaws.com';
const resizeImageServerUrl = 'https://d8ojjhfh4a4yx.cloudfront.net';
const ticketImageServerUrl = 'https://s3.amazonaws.com/ruckit-platform/';
const errorTrackingEnv = 'production';
const googleMapsKey = 'AIzaSyBnyQsblAiJeVVAHKQyo76Nx8PVp-N2mLU';
const applicationVersion = '2022.01.18.001-890b8cb';
const scaleitUrl = 'https://api.scaleitusa.com/api/ruckit/v1/';
const ptpRuckitKey = 'e108b31d6e1462fa638145ae69439ad64cc02949';
const ptpApiKey = '958616b01e9bb274ff6278e02b19d9f3';
const ptpUrl = 'https://app.pitticketpro.com/partner_api/ticket_manager';

export const environment = {
  production: production.toString() === 'false',
  serverUrl,
  imageServerUrl,
  resizeImageServerUrl,
  ticketImageServerUrl,
  scaleitUrl,
  ptpRuckitKey,
  ptpApiKey,
  ptpUrl,
  errorTrackingEnv,
  applicationVersion,
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  googleMapsKey
};
